import axios from "axios";
import { useEffect, useState } from "react";
import { env } from "../../configs/EnvironmentConfig";
import { Col, Form, Row, Select, Tabs } from "antd";
import ApiConfig from "../../configs/ApiConfig";
//import utils from "utils";
//import { MoreOutlined } from "@ant-design/icons";
//import { FilterIcon } from "assets/svg/icon";

const Filters = ({
  current,
  leaguesList,
  setLeaguesList,
  league,
  setLeague,
  season,
  setSeason,
  seasonList,
  setSeasonList,
  tier,
  setTier,
  tiersList,
  setTiersList,
  allLeagueListasObject,
  setAllLeagueListasObject,setIsDraft,isDraft,setDraft,draft
}) => {
  const [form] = Form.useForm();
  //const { useBreakpoint } = Grid;
  //const screens = utils.getBreakPoint(useBreakpoint());
  //const isMobile = screens.length === 0 ? false : !screens.includes("lg");
  //   const [leaguesList, setLeaguesList] = useState([]);
  //   const [league, setLeague] = useState("Sunday League");
  //   const [season, setSeason] = useState("");
  //   const [seasonList, setSeasonList] = useState([]);
  //   const [tier, setTier] = useState("1");
  //   const [tiersList, setTiersList] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  //const [open,setOpen] = useState(false)
  //const [allLeagueListasObject,setAllLeagueListasObject] = useState({});
  const { Option } = Select;
  const getLeagueNameFromAllLeagueList = (data) => {
    let leagueListName = {};
    // eslint-disable-next-line no-unused-vars
    let v1 = data?.forEach((item) => {
      leagueListName[item._id] = item.name;
    });
    return leagueListName;
  };

  //function called on season change to set league list and league for league drop down
  const updateLeagueListOnSeasonChangefromFilterData = (seasonid) => {
    console.log("at line 35");
    const index = filterdata?.findIndex((item) => item?._id === seasonid);
    const leagueslistlocal = filterdata?.[index]?.leagues
    ?.filter((item) => !item?.is_archived)?.map((item) => {
      return { value: item?.league, name: allLeagueListasObject[item?.league],is_archived:item?.is_archived };
    });
    console.log(filterdata?.[index]?.leagues?.[0],'filterdata?.[index]?.leagues?.[0]')
    setIsDraft((filterdata?.[index]?.leagues?.[0]?.status)==="Draft")
    setDraft({...(filterdata?.[index]?.leagues?.[0]?.draft)})
    setLeague(allLeagueListasObject[filterdata?.[index]?.leagues?.filter((item) => !item?.is_archived)?.[0]?.league]);
    form.setFieldsValue({
      league: allLeagueListasObject[filterdata?.[index]?.leagues?.filter((item) => !item?.is_archived)?.[0]?.league],
    });
    setLeaguesList([...leagueslistlocal]);

    // updating tier as league is changed
    const tierlistlocal = filterdata?.[index]?.leagues?.[0]?.tiers?.filter(item=>item.registered_teams?.length)?.map(
      (item) => item.tier
    );
    setTier(filterdata?.[index]?.leagues?.[0]?.tiers?.[0]?.tier);
    form.setFieldsValue({
      tier: filterdata?.[index]?.leagues?.[0]?.tiers?.[0]?.tier,
    });
    console.log(tierlistlocal, "tierlistlocal");
    if (tierlistlocal?.length === 1) {
      setTiersList([
        ...tierlistlocal,
        tierlistlocal?.length === 1 ? undefined : "All",
      ]);
    } else {
      setTiersList([
        ...tierlistlocal,
        tierlistlocal?.length === 1 || (filterdata?.[index]?.leagues?.[0]?.status)==="Draft" ? undefined : "All",
      ]);
    }
  };
  //function called on initial load to set tier list and tier for tier drop down
  const updateTierListOnLeagueChangefromFilterData = (leagueid) => {
    console.log("at line 60");
    const seasonindex = filterdata?.findIndex((item) => item?._id === season);
    const leagueindex = leaguesList?.findIndex(
      (item) => item?.name === leagueid
    );
    const leagueslistlocal = filterdata?.[seasonindex]?.leagues
    ?.filter((item) => !item?.is_archived)?.map((item) => {
      return { value: item?.league, name: allLeagueListasObject[item?.league],is_archived:item?.is_archived };
    });
    console.log(filterdata?.[seasonindex]?.leagues?.[leagueindex],'filterdata?.[index]?.leagues?.[0]')
    setIsDraft((filterdata?.[seasonindex]?.leagues?.[leagueindex]?.status)==="Draft")
    setDraft({...(filterdata?.[seasonindex]?.leagues?.[leagueindex]?.draft)})
    setLeague(
      allLeagueListasObject[
        filterdata?.[seasonindex]?.leagues?.[leagueindex]?.league
      ]
    );
    form.setFieldsValue({
      league:
        allLeagueListasObject[
          filterdata?.[seasonindex]?.leagues?.[leagueindex]?.league
        ],
    });
    setLeaguesList([...leagueslistlocal]);

    // updating tier as league is changed
    const tierlistlocal = filterdata?.[seasonindex]?.leagues?.[
      leagueindex
    ]?.tiers?.filter(item=>item.registered_teams?.length)?.map((item) => item.tier);
    setTier(
      filterdata?.[seasonindex]?.leagues?.[leagueindex]?.tiers?.[0]?.tier
    );
    form.setFieldsValue({
      tier: filterdata?.[seasonindex]?.leagues?.[leagueindex]?.tiers?.[0]?.tier,
    });
    setTiersList([
      ...tierlistlocal,
      tierlistlocal?.length === 1 || ((filterdata?.[seasonindex]?.leagues?.[leagueindex]?.status)==="Draft")? undefined : "All",
    ]);
  };
  //function called on initial load to set season list and season for season drop down
  const getSeasonListOninitialLoadfromFilterData = (data) => {
    console.log(data, "datads");
    const seasonlistlocal = data?.filter(item=>item?.leagues?.filter(ite=>!(ite?.is_archived))?.length)?.map((item) => {
      return {
        value: item?._id,
        label: item?.name,
        is_archived: item?.is_archived,
      };
    });
    //sort((a,b)=>(a?.is_active>b?.is_active)?-1:(a?.is_active>b?.is_active)?1:-1)?.

    setSeason(data?.[0]?._id);
    form.setFieldsValue({ season: data?.[0]?._id });
    setSeasonList([...seasonlistlocal]);
  };
  //function called on initial load to set league list and league for league drop down
  const getLeagueListOninitialLoadfromFilterData = (data, leagueListName) => {
    const dat = [...data];
    const leagueslistlocal = dat?.[0]?.leagues
                  ?.filter((item) => !item?.is_archived)?.map((item) => {
      //sort((a,b)=>(a?.is_active>b?.is_active)?-1:(a?.is_active>b?.is_active)?1:-1)?.
      return { value: item?.league, name: leagueListName[item?.league],is_archived:item?.is_archived };
    });
    console.log(dat?.[0]?.leagues,'filterdata?.[index]?.leagues?.[0]',leagueListName[dat?.[0]?.leagues?.filter((item) => !item?.is_archived)?.[0]?.league])
    setIsDraft((dat?.[0]?.leagues?.[0]?.status)==="Draft")
    setDraft({...(dat?.[0]?.leagues?.[0]?.draft)})
    setLeague(leagueListName[dat?.[0]?.leagues?.filter((item) => !item?.is_archived)?.[0]?.league]); //sort((a,b)=>(a?.is_active>b?.is_active)?-1:(a?.is_active>b?.is_active)?1:-1)?.
    form.setFieldsValue({
      league: leagueListName[dat?.[0]?.leagues?.filter((item) => !item?.is_archived)?.[0]?.league],
    }); //sort((a,b)=>(a?.is_active>b?.is_active)?-1:(a?.is_active>b?.is_active)?1:-1)?.
    setLeaguesList([...leagueslistlocal]);
  };
  //function called on initial load to set tier list and tier for tier drop down
  const getTierListOninitialLoadfromFilterData = (data) => {
    console.log(data?.[0]?.leagues?.[0]?.tiers,'data?.[0]?.leagues?.[0]?.tierss')
    const tierlistlocal = data?.[0]?.leagues?.[0]?.tiers?.filter(item=>item.registered_teams?.length)?.map(
      (item) => item.tier
    );
    //setTier(data?.[0]?.leagues?.[0]?.tiers?.[0]?.tier)
    //form.setFieldsValue({tier:data?.[0]?.leagues?.[0]?.tiers?.[0]?.tier})
    if (tierlistlocal?.length === 1) {
      setTiersList([...tierlistlocal]);
    } else {
      setTiersList([
        ...tierlistlocal,
        tierlistlocal?.length === 1 || ((data?.[0]?.leagues?.[0]?.status)==="Draft") ? undefined : "All",
      ]);
    }
    //setTiersList([...tierlistlocal,(tierlistlocal?.length===1)?undefined:'All'])//,(current==='stats' || current==='scores' || current==='schedule')?'All':undefined
  };
  //Function called to get filter data with season>>league>>tier object
  const getFilters = async () => {
    const response = await axios.get(
      `${env?.API_ENDPOINT_URL}/settings/getfilters`
    );
    setFilterData([...(response?.data?.filtersInfo ?? [])]);
    const leaguesResponse = await axios.get(
      `${env?.API_ENDPOINT_URL}/settings/leagueslist`
    );
    const leagueListName = getLeagueNameFromAllLeagueList(
      leaguesResponse?.data
    );
    setAllLeagueListasObject({ ...leagueListName });
    getLeagueListOninitialLoadfromFilterData(
      response?.data?.filtersInfo ?? [],
      leagueListName
    );
    getSeasonListOninitialLoadfromFilterData(response?.data?.filtersInfo ?? []);
    getTierListOninitialLoadfromFilterData(response?.data?.filtersInfo ?? []);
  };
  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
    setTier(isDraft?"registered":"All");
    form.setFieldsValue({ tier: isDraft?"registered":"All" });
    // if(current==='stats' || current==='scores' || current==='schedule') {
    // } else {
    //   setTier(tiersList?.[0])
    //   form.setFieldsValue({tier:tiersList?.[0]})
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season, league]);
  const [items, setItems] = useState([]);

  const onChange = (key) => {
    console.log(key,'keykey')
    setTier(key);
  };
  
  const [settings, setsettings] = useState({});
  // eslint-disable-next-line no-unused-vars
  const c1 = {"Thursday League":"6289f76ebd1d158a386ea893",
    "Sunday League":"626fad6d75280628c46c18c8",
    "Saturday League":"626fa5a98a5c29264288c85a",
    "Wednesday League":"62823b02b63039209ef70d5b",
    "Tuesday League":"6289f769bd1d158a386ea88c",
    "Monday League":"6289f762bd1d158a386ea885",
    "Friday League":"6289f773bd1d158a386ea89a"}
  const getaccountsettings = async () => {
    try {
      const response = await axios.get(
        `${env?.API_ENDPOINT_URL}/${ApiConfig.website.getaccountsettings}`
      );
      const v1= {}
      response?.data?.[0]?.divisions?.forEach((item)=>{
        v1[item.divisionid]=item.label
      })
      const v2= {}
      response?.data?.[0]?.leagues?.forEach((item)=>{
        v2[item.leagueid]=item.label
      })
      setsettings({ leagues:{...v2},divisions:{...v1} });
      // form.setFieldsValue({homepage:response?.data?.[0]?.homepage,
      //   home:response?.data?.[0]?.hiddenmenu?.includes("/home"),
      //   rule:response?.data?.[0]?.hiddenmenu?.includes("/rule"),
      //   portal:response?.data?.[0]?.portal,
      //   rulelink:response?.data?.[0]?.rule
      // })
    } catch (error) {
      return [];
    }
  };
  useEffect(()=>{
    getaccountsettings()
  },[])
  console.log(settings,'settings',tiersList,(settings?.divisions?.['20']))
  useEffect(() => {
    setItems([isDraft?{key:'registered',label:'Registered'}:null,
      ...tiersList
        ?.sort((a, b) =>
          b === "All" ? -1 : parseInt(a) > parseInt(b) ? 1 : -1
        )
        .map((state) =>
          Boolean(tiersList?.[0])
            ? 
            process.env.REACT_APP_DEV_API_REDIRECT_URL.includes("tcbl")
              ? {
                  key: state,
                  label: state==="All"?state:((settings?.divisions?.[state])??state),
                  children: null,
                }
              : state === "1"
              ? "East"
              : state === "2"
              ? "West"
              : Boolean(tiersList?.[0])
              ? "No Tier"
              : "All"
            : "No Tier"
        )
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiersList,settings]);
  console.log(items, "itemsitems");
  //todo removed for filter options
  // {isMobile?<div style={{margin:'-20px'}}><Row>
  //             <Col  xs={21}>
  //               <div style={{fontSize:'16px',height:'55px',padding:'5px', color:'#000'}}>
  //               <Form.Item name="season" style={{width:'100%'}}>
  //               <Select placeholder="Season" bordered={false} onChange={(e)=>{setSeason(e);updateLeagueListOnSeasonChangefromFilterData(e)}} value={season}>
  //                 {seasonList?.filter(item=>!item?.is_archived)?.map((state) => (
  //                   <Option key={state?.value} value={state?.value}>
  //                     <span style={{color:"#000", fontWeight:"700"}}>
  //                     {state?.label}
  //                     </span>
  //                   </Option>
  //                 ))}
  //               </Select>
  //             </Form.Item>
  //               </div>
  //             </Col>
  //             <Col  xs={3}>
  //               <div style={{borderRight:"solid 1px rgb(105,105,105,0.2)",padding:'15px'}}>
  //               <span onClick={()=>setOpen(!open)}><FilterIcon /></span>
  //             <Modal open={open} onCancel={()=>setOpen(false)} okButtonProps={{hidden:true}} cancelButtonProps={{hidden:true}} title="Filters" styles={{header:{backgroundColor:'black'}}}>

  //               <Row>
  //               <Col xs={24} sm={24} md={6}  hidden={current === "leaders"}>
  //             <Form.Item name="league">
  //               <Select
  //                 placeholder="League"
  //                 onChange={(e)=>{setLeague(e);setOpen(false);updateTierListOnLeagueChangefromFilterData(e)}}
  //                 value={league}
  //               >
  //                 {leaguesList?.map((state) => (
  //                   <Option key={state?.name} value={state?.name}>
  //                     {state?.name}
  //                   </Option>
  //                 ))}
  //               </Select>
  //             </Form.Item>
  //           </Col>

  //           <Col xs={24} sm={24} md={3}  hidden={current === "leaders"}>
  //             <Form.Item name="tier">
  //               <Select
  //                 placeholder="Tier"
  //                 onChange={(e)=>{setTier(e);setOpen(false)}}
  //                 value={tier}
  //                 disabled={!Boolean(tiersList?.[0])}
  //               >
  //                 {tiersList?.sort((a,b)=>b==='All'?-1:(parseInt(a)>parseInt(b)?1:-1)).map((state) => (
  //                   <Option key={state} value={state}>
  //                     {(Boolean(tiersList?.[0]) )? ((process.env.REACT_APP_DEV_API_REDIRECT_URL).includes('tcbl'))?state:((state === '1')?'East':(state === '2')?'West':(Boolean(tiersList?.[0]))?'No Tier':'All')  : "No Tier"}
  //                   </Option>
  //                 ))}
  //               </Select>
  //             </Form.Item>
  //           </Col>
  //               </Row>
  //             </Modal>
  //             </div>
  //             </Col>
  //             {/* <Col  xs={3}>
  //               <div style={{padding:'15px'}}>
  //             <MoreOutlined style={{
  //               fontSize: "20px"
  //             }}
  //             size={"large"}/>
  //             </div>
  //             </Col> */}
  //           </Row>
  //         </div>:
  return (
    <div>
      <Form form={form}>
        <Row gutter={16}>
          <Col xs={12} sm={12} md={6}>
            <Form.Item name="season">
              <Select
                placeholder="Season"
                onChange={(e) => {
                  setSeason(e);
                  updateLeagueListOnSeasonChangefromFilterData(e);
                }}
                value={season}
              >
                {seasonList
                  ?.filter((item) => !item?.is_archived)
                  ?.map((state) => (
                    <Option key={state?.value} value={state?.value}>
                      {state?.label}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          {console.log(settings?.leagues,league,'ettings?.leagues,leagu',leaguesList)}
          <Col xs={12} sm={12} md={6}>
            <Form.Item name="league">
              <Select
                placeholder="League"
                onChange={(e) => {
                  setLeague(e);
                  updateTierListOnLeagueChangefromFilterData(e);
                }}
                value={league}
              >
                {leaguesList
                  ?.map((state) => (
                  <Option key={state?.name} value={state?.name}>
                    {((settings?.leagues?.[state?.value])??state?.name)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col xs={24} sm={24} md={3}>
              <Form.Item name="tier">
                <Select
                  placeholder="Tier"
                  onChange={setTier}
                  value={tier}
                  disabled={!Boolean(tiersList?.[0])}
                >
                  {tiersList?.sort((a,b)=>parseInt(a)>parseInt(b)?1:-1).map((state) => (
                    <Option key={state} value={state}>
                      {Boolean(tiersList?.[0]) ? (process.env.REACT_APP_DEV_API_REDIRECT_URL).includes('tcbl')?state:(state === '1'?'East':state === '2'?'West':'All')  : "No Tier"}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
        </Row>
      </Form>
      <Tabs
        defaultActiveKey="1"
        style={{ marginBottom: "-30px" }}
        onChange={onChange}
        items={items}
        activeKey={tier}
      ></Tabs>
    </div>
  );
};
export default Filters;
